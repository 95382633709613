import React from 'react'

import PropTypes from 'prop-types'

import './not-found4.css'

const NotFound4 = (props) => {
  return (
    <div
      id="workingon"
      className={`not-found4-container thq-section-padding ${props.rootClassName} `}
    >
      <img
        src={props.imageSrc1}
        alt={props.imageAlt1}
        className="not-found4-image"
      />
    </div>
  )
}

NotFound4.defaultProps = {
  rootClassName: '',
  imageSrc1: '/Windows98/98-1500w.png',
  imageAlt1: 'image',
}

NotFound4.propTypes = {
  rootClassName: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt1: PropTypes.string,
}

export default NotFound4
