import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import NotFound4 from '../components/not-found4';
import Hero from '../components/hero';
import Footer from '../components/footer';
import Gallery from '../components/gallery';
import Cookie from '../components/cookie';
import './home.css';
import Win98Navbar from '../components/win98-navbar';

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Brunty website</title>
        <meta property="og:title" content="Brunty" />
      </Helmet>
      <Cookie /> {/* Použití komponenty Cookie */}
      <Win98Navbar /> {/* Použití komponenty Win98Navbar */}
      <Hero /> {/* Použití komponenty Hero */}
      <NotFound4 /> {/* Použití komponenty NotFound4 */}
      <Footer /> {/* Použití komponenty Footer */}
      <div>
        <div className="home-container3">
          <script
            dangerouslySetInnerHTML={{
              __html: `ScrollReveal().reveal('.speaker');
                         ScrollReveal().reveal('.image-notes');`
            }}
          />
        </div>
      </div>
      <div>
        <div className="home-container5">
          <style
            dangerouslySetInnerHTML={{
              __html: `#scrollbar::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                        border-radius: 10px;
                        background-color: #F5F5F5;
                      }

                      #scrollbar::-webkit-scrollbar {
                        height: 12px;
                        background-color: #F5F5F5;
                        border-radius: 10px;
                      }

                      #scrollbar::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                        background-color: #555;
                      }`
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
