import { useEffect } from 'react';

const Cookie = () => {
  useEffect(() => {
    console.log('useEffect called');

    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.innerHTML = `
      var _iub = _iub || [];
      _iub.csConfiguration = {"siteId":3724323,"cookiePolicyId":74873079,"lang":"cs"};
    `;
    document.body.appendChild(script1);
    console.log('Script 1 added');

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = 'https://cs.iubenda.com/autoblocking/3724323.js';
    script2.onload = () => console.log('Script 2 loaded');
    script2.onerror = () => console.log('Script 2 error');
    document.body.appendChild(script2);

    const script3 = document.createElement('script');
    script3.type = 'text/javascript';
    script3.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
    script3.charset = 'UTF-8';
    script3.async = true;
    script3.onload = () => console.log('Script 3 loaded');
    script3.onerror = () => console.log('Script 3 error');
    document.body.appendChild(script3);
  }, []);

  return null;
};

export default Cookie;
