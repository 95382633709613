import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import './style.css';
import Home from './views/home';
import NotFound from './views/not-found';
import Cookie from './components/cookie'; // Import komponenty Cookie

const App = () => {
  return (
    <Router>
      <Cookie /> {/* Použití komponenty Cookie */}
      <div>
        <Switch>
          <Route component={Home} exact path="/" />
          <Route component={NotFound} path="**" />
          <Redirect to="**" />
        </Switch>
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
