import React from 'react';

const Hero = () => {
  return (
    <section className="home-hero">
      <div className="home-background">
        <img alt="image" src="/logo1-1500w.png" className="home-image" />
      </div>
      <div className="home-hero-content">
        <p className="home-text6">Vítej na Bruntyho stránce!</p>
      </div>
    </section>
  );
};

export default Hero;
