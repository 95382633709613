// components/gallery.js
import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/)).map(image => ({
  original: image,
  thumbnail: image,
}));

const Gallery = () => {
  // Pokud nejsou žádné obrázky, vrátí null
  if (images.length === 0) {
    return null;
  }

  return (
    <ImageGallery 
      items={images}
      showThumbnails={true}
      showFullscreenButton={true}
      showPlayButton={true}
      showBullets={true}
      autoPlay={true}
      slideInterval={5000}
      useBrowserFullscreen={true}
      showNav={true}  // Přidání navigačních šipek
    />
  );
};

export default Gallery;
