import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './win98-navbar.css'

const Win98Navbar = (props) => {
  return (
    <header className={`win98-navbar-container ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="win98-navbar-navbar-interactive">
        <div data-thq="thq-navbar-nav" className="win98-navbar-desktop-menu">
          <Link to="/" className="win98-navbar-navlink">
            <img
              alt={props.imageAlt}
              src={props.imageSrc}
              className="win98-navbar-image"
            />
          </Link>
          <nav className="win98-navbar-links">
            <a href="#workingon" className="win98-navbar-link">
              <img
                alt={props.imageAlt2}
                src={props.imageSrc2}
                className="win98-navbar-image01"
              />
            </a>
            <a href="#workingon" className="win98-navbar-link1">
              <img
                alt={props.imageAlt3}
                src={props.imageSrc3}
                className="win98-navbar-image02"
              />
            </a>
            <a href="#workingon" className="win98-navbar-link2">
              <img
                alt={props.imageAlt5}
                src={props.imageSrc5}
                className="win98-navbar-image03"
              />
            </a>
            <a href="#workingon" className="win98-navbar-link3">
              <img
                alt={props.imageAlt4}
                src={props.imageSrc4}
                className="win98-navbar-image04"
              />
            </a>
          </nav>
        </div>
        <div data-thq="thq-burger-menu" className="win98-navbar-burger-menu">
          <Link to="/">
            <img
              alt={props.imageAlt7}
              src={props.imageSrc7}
              className="win98-navbar-image05"
            />
          </Link>
          <nav className="win98-navbar-links1">
            <a href="#workingon" className="win98-navbar-link4">
              <img
                alt={props.imageAlt21}
                src={props.imageSrc21}
                loading="lazy"
                className="win98-navbar-image06"
              />
            </a>
            <a href="#workingon" className="win98-navbar-link5">
              <img
                alt={props.imageAlt31}
                src={props.imageSrc31}
                className="win98-navbar-image07"
              />
            </a>
            <a href="#workingon" className="win98-navbar-link6">
              <img
                alt={props.imageAlt51}
                src={props.imageSrc51}
                className="win98-navbar-image08"
              />
            </a>
            <a href="#workingon" className="win98-navbar-link7">
              <img
                alt={props.imageAlt41}
                src={props.imageSrc41}
                className="win98-navbar-image09"
              />
            </a>
          </nav>
        </div>
      </header>
    </header>
  )
}

Win98Navbar.defaultProps = {
  imageSrc7:
    '/Windows98/start-button-dont-let-them-take-the-bottom-left-lol-v0-ostbbhrivxq81-200h.png',
  rootClassName: '',
  imageAlt7: 'image',
  imageAlt31: 'image',
  imageSrc4: '/Windows98/camera3_network-2-200h.png',
  imageAlt21: 'image',
  imageSrc3: '/Windows98/user_computer-1-200h.png',
  imageAlt51: 'image',
  imageSrc21: '/Windows98/homepage_alt-200h.png',
  imageAlt4: 'image',
  imageSrc51: '/Windows98/paint_file-3-200h.png',
  imageSrc:
    '/Windows98/start-button-dont-let-them-take-the-bottom-left-lol-v0-ostbbhrivxq81-200h.png',
  imageAlt5: 'image',
  imageAlt41: 'image',
  logoSrc: 'https://play.teleporthq.io/static/svg/placeholders/no-image.svg',
  imageAlt3: 'image',
  imageSrc5: '/Windows98/paint_file-3-200h.png',
  imageSrc31: '/Windows98/user_computer-1-200h.png',
  imageAlt2: 'image',
  imageAlt: 'image',
  imageSrc2: '/Windows98/homepage_alt-200h.png',
  imageSrc41: '/Windows98/camera3_network-2-200h.png',
}

Win98Navbar.propTypes = {
  imageSrc7: PropTypes.string,
  rootClassName: PropTypes.string,
  imageAlt7: PropTypes.string,
  imageAlt31: PropTypes.string,
  imageSrc4: PropTypes.string,
  imageAlt21: PropTypes.string,
  imageSrc3: PropTypes.string,
  imageAlt51: PropTypes.string,
  imageSrc21: PropTypes.string,
  imageAlt4: PropTypes.string,
  imageSrc51: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt5: PropTypes.string,
  imageAlt41: PropTypes.string,
  logoSrc: PropTypes.string,
  imageAlt3: PropTypes.string,
  imageSrc5: PropTypes.string,
  imageSrc31: PropTypes.string,
  imageAlt2: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc2: PropTypes.string,
  imageSrc41: PropTypes.string,
}

export default Win98Navbar
